"use strict";

var OLDIE

const fade_time = 500;

// utility flags to avoid scanning DOM
//let is_over_54 = false;
let has_valid_plan = false;
let has_sections_chosen = false;

let chosen_sections = {
    day2day: false,
    hospital: false,
    funeral: false
}

let chosen_benefits = {

  day2day: {
    gp: 1,
    medication: 1,
    pathology_radiology: 1,
    dentistry: 1,
    specialist: 1,
    optometry: 1
  },

  hospital: {
    accident: 2,
    illness: 2,
    maternity: 0,
    emergency_casualty_visits: 1,
    dread: 0,
    disability: 0,
    icu: 1
  },

  funeral: {
    funeral_cover: 1
  }
};


function getCookie(a) {
    var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
    return decodeURIComponent(b ? b.pop() : '');
};


// display the premium value
function updatePremiumValue(value) {
  let p = value.premium;
  $('#premium-value').text(`R ${p.toFixed(2)}`);
  if (p > 0) {
      //enable save button
      $("#save-quote").prop("disabled", false);
  } else {
      //disable save button
      $("#save-quote").prop("disabled", true);
  }
}


// display ? in the premium field
function updatePremiumValueFailed(value) {
  console.log("PRemium update failed")
  console.log(value)
  $('#premium-value').text(" ? ");
  //disable save button
  $("#save-quote").prop("disabled", true);
}

function getNewPremiumPrice() {
    if (isProductValid()) {
        let quote_params = getQuoteParameters()
        var loader = $("#save-quote-spinner");
        loader.removeClass("hide")
        $.ajax({
            type: "POST",
            url: "/price",
            data: JSON.stringify(quote_params),
            success: updatePremiumValue,
            error: updatePremiumValueFailed,
            complete: function () { loader.addClass('hide'); },
            dataType: "json",
            contentType: "application/json"
        });

    } else {
        updatePremiumValue({"premium": 0})
    }
}



// has the user chosen enough stuff for us to allow them to submit the quote?
function isProductValid() {
    return has_valid_plan && has_sections_chosen
};


// scan through everything and find out what we want to submit for a price update
function getQuoteParameters() {
    let quote_params = {}
    let numadults = $("#numadults").val()
    let numchildren = $("#numchildren").val()
    quote_params["adults"] = $("#numadults").val()
    quote_params["dependants"] = $("#numchildren").val()
    //let plan = $("#familysize").val().split(",");
//    let plan = [numadults, numchildren]
//    if (plan.length < 2) {
//        //error, someohow no familysize given us
//        plan = ["single", "0"]
//    }
//    quote_params["adults"] = plan[0]
//    quote_params["dependants"] = parseInt(plan[1])

//    quote_params["stated_over_54"] = is_over_54

    quote_params["sections"] = {}
    $.each(chosen_sections, function(key, value) {
        if (value) {
            quote_params["sections"][key] = chosen_benefits[key]
        }
    });
    //console.log(quote_params)
    return quote_params;
};


// launch modal for a specific benefit
function benefitModalShow(section, benefit) {
  $("#" + "benefit-modal-" + section + "-" + benefit).modal("show");
}

// user selected a specific section/benefit/level from the modal
function chooseBenefit(section, benefit, level) {
    /* illness hospitalisation warning */
    if (section === "hospital" && benefit === "illness" && level === 0) {

        let count = 0;
        let dropem = []

        if (chosen_benefits.hospital.maternity > 0) {
            count = count + 1;
            dropem.push("<em>Maternity</em>");
        }
        if (chosen_benefits.hospital.dread > 0) {
            count = count + 1;
            dropem.push("<em>Dread Disease</em>");
        }
        if (chosen_benefits.hospital.icu > 0) {
            count = count + 1;
            dropem.push("<em>ICU</em>");
        }

        let benefit_sub_html = dropem.join(", ");

        if (count > 0) {
            let plural_reference = (count > 1 ? "them" : "it");
            let html =
                "<p>but Illness Hospitalisation is a requirement for " + benefit_sub_html + "." +
                "<p>Would you like to drop cover for " + plural_reference + "?</p>";


            if (OLDIE) {
                setBenefit(section, benefit, level);
                zeroIllnessHospital()
            } else {
                swal({
                    title: "One sec...",
                    html: html,
                    type: "warning",
                    confirmButtonText: "Yes",
                    showCancelButton: true,
                }).then(function() {
                    setBenefit(section, benefit, level);
                    zeroIllnessHospital()
                    getNewPremiumPrice()
                });
            }
            getNewPremiumPrice()
            return;
        }
    }
    setBenefit(section, benefit, level);
    zeroIllnessHospital()
    getNewPremiumPrice()
}

// there is a dependancy between Illness Hospitalisation and Dread and Maternity
function zeroIllnessHospital() {
    var alerts = $("#dread-alert").add("#maternity-alert").add("#icu-alert");
    var buttons = $("#benefit-modal-hospital-dread").add("#benefit-modal-hospital-maternity").add("#benefit-modal-hospital-icu").find(".btn-pick");

    if (chosen_benefits.hospital.illness === 0) {
        //setBenefit("hospital", "illness", 0);
        setBenefit("hospital", "dread", 0);
        setBenefit("hospital", "maternity", 0);
        setBenefit("hospital", "icu", 0);

        alerts.show();
        buttons.hide();
    } else {
        alerts.hide();
        buttons.show();
    }
}

// user selected a benefit level - update circle indicator and save choice
function setBenefit(section, benefit, level) {
  chosen_benefits[section][benefit] = level;
  let selector = "button[data-section=" + section + "][data-benefit=" + benefit + "]";
  $(selector).html(level);
}

function turnOnFuneral() {
    $("#sections-funeral").fadeIn(fade_time);
}

function turnOffFuneral() {
    $("#sections-funeral").fadeOut(fade_time);
    $("#chk-funeral").prop("checked", false);
    $("#funeral").hide(fade_time);
}

// if Over 54 is selected then animate stuff and set other stuff
//function over54Changed() {
//    if ($("#chk-over54").prop("checked")) {
//        is_over_54 = true;
//        //$("#alert-over54").show(fade_time);
//        $("#sections-hospital").fadeOut(fade_time);
//        $("#chk-hospital").prop("checked", false);
//        $("#hospital").hide(fade_time);
//        turnOffFuneral()
//    } else {
//        is_over_54 = false;
//        //$("#alert-over54").hide(fade_time);
//        $("#sections-hospital").fadeIn(fade_time);
//        turnOnFuneral
//    }
//    sectionChoiceChanged()
//    //getNewPremiumPrice()
//}


// user selected a family size
function familySizeChanged() {
    if ($("#numadults").val() !== "" && $("#numchildren").val() !== "") {
        has_valid_plan = true;
        $("#sections").slideDown(fade_time);
    } else {
        has_valid_plan = false;
        $("#sections").slideUp(fade_time);
    }

//    if ($("#familysize").val() !== "") {
//        has_valid_plan = true;
//        $("#sections").slideDown(fade_time);
//    } else {
//        has_valid_plan = false;
//        $("#sections").slideUp(fade_time);
//    }
    getNewPremiumPrice()
}


// show or hide the benefit choices blocks
function displayBenefitChoices(sections) {
    let chosen = [];
    let not_chosen = [];
    $.each(sections, function(section){
        if (sections[section]) {
            chosen.push("#" + section)
        } else {
            not_chosen.push("#" + section)
        }
    });
    if (chosen.length > 0) {
        $("#benefits > p").slideDown(fade_time);
    } else {
        $("#benefits > p").slideUp(fade_time);
    };

    $(chosen.join(", ")).slideDown(fade_time)
    $(not_chosen.join(", ")).slideUp(fade_time)
    //console.log(chosen_sections)
    //console.log(isProductValid())
}



// user selected a section
function sectionChoiceChanged() {
    chosen_sections.day2day = $("#chk-day2day").prop("checked");
    chosen_sections.hospital = $("#chk-hospital").prop("checked");

    if (!chosen_sections.day2day && !chosen_sections.hospital) {
        // can't have funeral
        let sel_vas = $("#chk-funeral")
        has_sections_chosen = false;
        sel_vas.prop("checked", false)
        sel_vas.prop("disabled", true);
        sel_vas.parent().parent().slideUp(fade_time);
        chosen_sections.funeral = false

    } else {
        has_sections_chosen = true;
        let sel_funeral = $("#chk-funeral")
        sel_funeral.prop("disabled", false);
        sel_funeral.parent().parent().slideDown(fade_time);
        chosen_sections.funeral = sel_funeral.prop("checked");
    };
    displayBenefitChoices(chosen_sections)
    getNewPremiumPrice()
};

function saveQuote() {
    var parsleyForm = $("#save-modal-form").submit(function (event) {
      event.preventDefault();
    }).parsley();

    if (!parsleyForm.validate()) {
      return;
    }
    var $loader = $(".save-quote-confirm-spinner");
    var $savebutton = $(".save-quote-confirm")
    var $modal = $("#save-modal");
    var $savequote = $("#save-quote")
    $savequote.prop("disabled", true);
    $loader.removeClass('hide');
    $savebutton.prop("disabled", true);

    var data = getQuoteParameters();

    data['first_name'] = $('#txt-first-name').val();
    data['last_name'] = $('#txt-last-name').val();
    data['email'] = $('#txt-email').val();
    data['phone'] = $('#txt-phone').val();
    data['tmt_data'] = sessionStorage.getItem("tmt_data") || getCookie("tmt_data")

    let email_client = $("#email-client")
    if (email_client.length > 0) {
        data["copy_client"] = email_client.prop("checked")
    } else {
        data["copy_client"] = false
    }

    $.ajax({
        type: "POST",
        url: "/quote",
        data: JSON.stringify(data),
        success: function success(value) {
            if (OLDIE) {
                window.location = "/quote/" + value.quote_number
            } else {
                let swal_text = ""
                if (window.AGENT_LOGGED_IN) {
                    swal_text = "Quote saved for the client."
                } else {
                    swal_text = "We've locked your quote in and set it aside. Please check your email!"
                }
                swal({title: "Saved!", text: swal_text, type: 'success'}).then(function() {
                    window.location.replace("/quote/" + value.quote_number + "/built")
                });
            }
        },
        error: function error(jqXHR) {
            $loader.addClass('hide');
            $modal.modal('hide');
            $savebutton.prop("disabled", false);
            $savequote.prop("disabled", false);
            if (OLDIE) {
                alert("Whoops! Something went wrong.\nDon't worry, it's not your fault, it's ours.\nSomeone has been told and they'd better fix it quick.")
                window.location = "/quote"
            } else {
                if (jqXHR.status >= 500) {
                    alert("Whoops! Something went wrong.\nDon't worry, it's not your fault, it's ours.\nSomeone has been told and they'd better fix it quick.")
                    window.location = "/quote"
                } else {
                    swal({
                        title: "Whoops!",
                        text: jqXHR.responseJSON.error,
                        type: 'error'
                    });
                }
            }
        },
//        complete: function complete() {
//            $loader.addClass('hide');
//            $modal.modal('hide');
//            $savebutton.prop("disabled", false);
//        },
        dataType: "json",
        contentType: "application/json"
    });
}

$(window).bind("pageshow", function() {
    $("#numadults").val("")
    $("#numchildren").val("")
//    $("#familysize").val("")
//    $("#chk-over54").prop("checked", false)
    $("#chk-day2day").prop("checked", false)
    $("#chk-hospital").prop("checked", false)
    $("#chk-funeral").prop("checked", false)
    sectionChoiceChanged()

});


$(document).ready(function() {
    $("#numadults").val("")
    $("#numchildren").val("")
    $("#chk-day2day").prop("checked", false)
    $("#chk-hospital").prop("checked", false)
    $("#chk-funeral").prop("checked", false)
    updatePremiumValue({"premium": 0})

    $("#numadults").change(familySizeChanged);
    $("#numchildren").change(familySizeChanged);
    $("#sections input").change(sectionChoiceChanged);

    $("#save-quote").click(function () { if (isProductValid()) { $('#save-modal').modal('show') } });

    $("#save-email-quote-confirm").click(saveQuote);

    $('#save-modal').on('hidden.bs.modal', function (e) {
        $(".save-quote-confirm").prop("disabled", false)
        $(".save-quote-confirm-spinner").addClass('hide');
    });

    if (typeof window.OLDIE  !== 'undefined') {
        OLDIE = window.OLDIE
    } else { OLDIE = false }
    console.log("OLDIE " + window.OLDIE)
});
